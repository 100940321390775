var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-10",attrs:{"id":"discountsPage"}},[_c('v-app-bar',{attrs:{"app":""}},[_c('v-toolbar-title',[_vm._v("كشف بهدايا المندوبين")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.$print(_vm.$refs.print)}}},[_c('v-icon',[_vm._v("la-print")]),_vm._v(" طباعة ")],1)],1),_c('v-card',{ref:"print",staticClass:"pa-10"},[_c('center',{staticClass:"printHeader"},[_c('h2',[_vm._v("كشف الهدايا")])]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"من تاريخ","type":"date"},model:{value:(_vm.search.from),callback:function ($$v) {_vm.$set(_vm.search, "from", $$v)},expression:"search.from"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","label":"الى تاريخ","type":"date"},model:{value:(_vm.search.to),callback:function ($$v) {_vm.$set(_vm.search, "to", $$v)},expression:"search.to"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchSearch()}}},[_vm._v(" بحث ")])],1)],1),_c('v-data-table',{attrs:{"items-per-page":"1000","hide-default-footer":"","items":_vm.discounts,"headers":_vm.discountsHeader,"multi-sort":""},scopedSlots:_vm._u([{key:"item.imagePath",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36"}},[(item.imagePath != null)?_c('img',{attrs:{"src":_vm.$baseUrl + 'files/' + item.imagePath}}):_vm._e(),(item.imagePath == null)?_c('img',{attrs:{"src":require("@/assets/no_image_placeholder.png")}}):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","target":"_BLANK","to":'/invoice/' + item.invoiceId}},[_c('v-icon',{attrs:{"title":item.invoiceId}},[_vm._v("mdi-magnify")])],1)]}},{key:"item.totalInvoicesPrice",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"green--text"},[_vm._v(_vm._s(item.totalInvoicesPrice.toLocaleString()))])]}},{key:"item.totalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalPrice.toLocaleString())+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }